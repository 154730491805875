/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		  
		  $('body').imagesLoaded({ background: '.image.background' }, function() {
			  // images have loaded
			  if(!$('body').is('.loaded')){
				  $('body').addClass("loaded");
				  //$('.banner, .wrap').fadeIn(600);
				  $('.loader').fadeOut(600, function(){
					  if(!$('body').is('.news') && !$('body').is('.archive')){
					  	  $(this).remove(); // remove the loader div if we don't need it
					  }
				  });
			  }
		  });
		  
		  
		  if(!$('body').is('.device')){
		  	viewportUnitsBuggyfill.init({refreshDebounceWait: 250});
		  }
		  
		  var win = $(window),
		  header = $('.banner'),
		  menuBtn = $('.lines-button'),
		  nav = $('.nav-primary'),
		  breakPoint = 768,
		  scrollThreshold = header.height(),
		  w = win.width(),
		  h = win.height();
		  
		  win.scroll(function(){
			  pos = win.scrollTop();
			  if(pos > scrollThreshold && w > breakPoint && !header.is('.scrolled')){
				header.addClass("scrolled");
			  }else if(pos < scrollThreshold && w > breakPoint && header.is('.scrolled')){
			  	header.removeClass("scrolled");
			  }
		  });
		 
		  
	  	/*******************************************************************************************************************

	  	MOBILE NAV BUTTON / OPEN & CLOSE

	  	*******************************************************************************************************************/
		  
		  
		  function onResize(){
			  w = win.width();
			  h = win.height();
			  if(w>=h){
				  $('body').addClass("landscape").removeClass("portrait");
			  }else if(w<h){
				  $('body').addClass("portrait").removeClass("landscape");
			  }
			  //
			  if(w > breakPoint && nav.css("max-height") === "0px"){
				  nav.attr("style", "");
			  }
		  }
		  
		  onResize();
		  
		  win.resize( onResize );
		  
		  menuBtn.click(function(e){
			  e.preventDefault();
			  //
			  if($(this).is('.open')){
				  $(this).removeClass('open');
				  nav.css("maxHeight", "0px");
			  }else if(!$(this).is('.open')){
				  $(this).addClass('open');
				  var contentHeight = $('.nav-primary').find('.content').outerHeight();
				  nav.css("maxHeight", contentHeight+"px");
			  }
			  //
			  return false;
		  });
		  
		  
	  	/*******************************************************************************************************************

	  	ARCHIVE FILTER BUTTONS

	  	*******************************************************************************************************************/
		  
		  $(document).on("click", '.filter-btn', function(e) {
			  e.preventDefault();
			  console.log('CLICKING FILTER BUTTON!!');
			  //
			  if($(this).is('.active')){
				  //
				  $(this).removeClass("active");
				  var drop = $(this).next();
				  drop.css("maxHeight", "0px");
				  //
			  }else if(!$(this).is('.active')){
				  //
				  $(this).addClass("active");
				  var drop = $(this).next(),
				  cH = drop.find('.dropdown').outerHeight();
				  drop.css("maxHeight", cH+"px");
				  //
			  }
			  //
			  return false;
		  });
		  
		  
	  	/*******************************************************************************************************************

	  	VIDEO LAYER OPEN / CLOSE

	  	*******************************************************************************************************************/
		  
		  $('a.video-open').click(function(e){
			  e.preventDefault();
			  //
			  if($('.video-overlay').is('.closed')){
				  $('.video-overlay').show().removeClass("closed");
				  setTimeout(function(){
	  				if($('.video-overlay').is('.youtube')){
	  					$('#youtube')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
	  				}else if($('.video-overlay').is('.vimeo')){
						var iframe = document.getElementById('vimeo');
						// $f == Froogaloop
						var player = $f(iframe);
						player.api("play");
	  				}
				  }, 1000)
			  }else if(!$('.video-overlay').is('.closed')){
			  	$('.video-overlay').addClass("closed");
				setTimeout(function(){ $('.video-overlay').hide(); }, 1000);
			  }
			  //
			  return false;
		  });
		  //
		  $('a.video-close, .video-overlay').click(function(e){
			  e.preventDefault();
			  //
			  if(!$('.video-overlay').is('.closed')){
			  	$('.video-overlay').addClass("closed");
				setTimeout(function(){
					$('.video-overlay').hide();
					if($('.video-overlay').is('.youtube')){
						$('#youtube')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
					}else if($('.video-overlay').is('.vimeo')){
						var iframe = document.getElementById('vimeo');
						// $f == Froogaloop
						var player = $f(iframe);
						player.api("pause");
						player.api("seekTo", 0);
					}
				}, 1000);
				//
			  }
			  //
			  return false;
		  });
		  
		  
	  	/*******************************************************************************************************************

	  	IMAGE GALLERY FOR SINGLE SHOW PAGES

	  	*******************************************************************************************************************/
		  
		  $(".show-column .gallery .content").royalSlider({
			autoplay: true,
			slidesOrientation: 'horizontal',
			loop: true,
			keyboardNavEnabled: true,
			speed: 900,
			slidesToShow: 1,
			slide: ".slide",
			slidesSpacing: 0,
			addActiveClass: true,
			controlNavigation: 'bullets',
			navigateByClick: true,
			arrowsNav: false,
			controlsInside: false,
		  });

		  // remove duplicates 
		  var duplicates = {};
		  $('.featured-awards .award').each(function () {
		  	var data = $(this).data('award');
			var txt = $(this).data('award');
			    if (duplicates[txt]) { 
			        $(this).remove();
			    } else { 
			        duplicates[txt] = true;
			    }
		  });
		  
		  $('a.awards-expand').click(function(e){
			  e.preventDefault();
			  //
			  $('.awards-table-row').show();
			  var table = $('.about-awards-table'),
			  text = $(this).find('.text'),
			  ico = $(this).find('.ico');
			  $('.img-wrap').removeClass('selected');
			  //
			  if(table.is('.collapsed') || text.html() == "Display"){
				  var cH = table.find('.content').outerHeight();
				  text.html("Hide");
				  ico.html("-");
				  table.removeClass("collapsed").css("maxHeight", cH+"px");
				  
			  }else if(!table.is('.collapsed')){
				  text.html("Display");
				  ico.html("+");
			  	  table.addClass("collapsed").css("maxHeight", "0px");
			  }
			  //
			  return false;
		  });
		  
		  
		  
		  
		  
	  	/*******************************************************************************************************************

	  	AJAX POST LOADING FOR ALL ARCHIVES

	  	*******************************************************************************************************************/
		  
		  if($('body').is('.archive')){
  		  	// JavaScript to be fired on the news page
  		  	if (!location.origin)
  		    	location.origin = location.protocol + "//" + location.host;
		  
  			var page = 1,
  			loading = true,
  			win = $(window),
  		  	url = location.origin,
  			content = $(".posts-container"),
  		  	more = $('.more-posts'),
  			moreBtn = $('.more-posts a'),
			isArchive = false,
  			$i = $('a.post').last().attr("data-i"),
  			$j = $('a.post').last().attr("data-j");
			//
			if(content.is('.archive')){
				isArchive = true;
				var type = content.attr('data-type');
				var category = content.attr('data-cat');
			}
			//
  			if($i == undefined){
  				$i = 0;
  			}
  			if($j == undefined){
  				$j = 0;
  			}
			//
  			load_posts = function(i, j){
  				//console.log('LOADING POSTS!!!');
  			  	$.ajax({
  			    	type       : "GET",
  			    	data       : {numPosts : 5, pageNumber: page, isArchive: isArchive, type: type, cat: category, $i: $i, $j: $j},
  			    	dataType   : "html",
  			    	url        : url+"/content/themes/jamfilled/load-handler.php",
  			    	beforeSend : function(){
  						if(page !== 1){
  							$('.loader').fadeIn(333);
  						}
  			    	},
  			    	success : function(data){
  			    	    $data = $(data);
  						//console.log('SUCCESSFULLY GOT CONTENT: '+JSON.stringify($data));
  						//console.log('THIS IS THE LENGTH OF THE DATA : '+$data.length);
  			    	    if($data.length !== 0){
  			    	    	//we still have data
  				    	    $data.hide();
  				    	    content.append($data);
  							$('.loader').fadeOut(333);
  				    	    $data.fadeIn(333, function(){
  				    	        loading = false;
  				    	    });

  				    	// hide archive list items 
  						// that are equal to the selected archive year 
  						$('.dropdown li').each(function(){
  							var year = $(this).find('a').html();
  							if(year === category){
								$(this).hide();
  							}

  						});	
  						
  			    	    }else{
  			    	    	$('.loader').fadeOut(333, function(){
  			    	    		more.slideToggle(333);
  			    	    	});
  			    	    }
  			    	},
  			    	error : function(jqXHR, textStatus, errorThrown) {
  			    	    alert(jqXHR + " :: " + textStatus + " :: " + errorThrown);
  			    	}
  				});
  			};
  			moreBtn.click(function(e){
  				e.preventDefault();
  				//
  				if(!loading){
  					loading = true;
  					page++;
  					$i = $('a.post').last().attr("data-i");
  					$i++;
  					$j = $('a.post').last().attr("data-j");
  					if($('a.post').last().is('.half')){
  						$j++;
  					}
  					load_posts();
  				}
  				//
  				return false;
  			});
  			//
  			win.scroll(function() {
  			    var content_offset = content.offset(); 
  			    if(!loading && (win.scrollTop() + win.height()) > (content.scrollTop() + content.height() + content_offset.top)) {
  					loading = true;
  					page++;
  					$i = $('a.post').last().attr("data-i");
  					$i++;
  					$j = $('a.post').last().attr("data-j");
  					if($('a.post').last().is('.half')){
  						$j++;
  					}
  					load_posts($i, $j);
  			    }
  			});
  			//
  			load_posts($i, $j);
		  }

		/*******************************************************************************************************************

	  	AWARD EVENT LISTENER 

	  	*******************************************************************************************************************/
	  	var currentAward = '';
	  	$(".featured-awards .award").on('click',function () {
	  		var data = $(this).data('award');
	  		var table = $('.about-awards-table');
	  		$('.awards-table-row').hide();
			$('.awards-table-row.header').show();
	  		$(table).find('.'+data).show();
	  		if(currentAward === ''){ currentAward = data; }
	  		var expanded = $('.awards-expand .text').html();
	  		if(expanded === "Display" || currentAward !== data ){
	  			$('.about-awards-table').removeClass('collapsed');
		  		var cH = table.find('.content').outerHeight();
		  		$('.about-awards-table').css({'maxHeight':cH+"px"});
		  		$('.awards-expand .text').html("Hide");
				$('.awards-expand .ico').html("-");
				$('.img-wrap').removeClass('selected');
				$(this).find('.img-wrap').addClass('selected');
	  		}else{
	  			$('.awards-expand .text').html("Display");
				$('.awards-expand .ico').html("+");
	  			$('.about-awards-table').addClass("collapsed").css("maxHeight", "0px");
	  			$('.img-wrap').removeClass('selected');
	  		}
 			currentAward = data;
	  		return false;
	  		
	  	});
		

		/*******************************************************************************************************************

	  	CAREERS PAGE 

	  	*******************************************************************************************************************/
		$('.jobs li').on('click',function(){
			var id = $(this).data('desc');
			if($('#'+id).is(':visible')){
				$('#'+id).slideUp();
				$(this).find('.plus-icon').removeClass('open');
			}else{
				$('#'+id).slideDown();
				$(this).find('.plus-icon').addClass('open');
			}
			 
			
		});

	  	 
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page		  
		  
		  if($('body').is('.device')){
  	  		// we are on mobile!!
  			var win = $(window),
  			scrollPosArr = [],
  			threshold = win.height()*0.5;
  			$('.home-section').each(function(i){
  				scrollPosArr[i] = $(this).offset().top;
  			}); 
			
  			win.scroll(function(){
  				checkScrollPos();
  			});
  			//
  			function checkScrollPos(){
  				var scrollPos = win.scrollTop();
  				$.each(scrollPosArr, function(i, pos){
  					if(scrollPos > pos-threshold){
  						$('.home-section:eq('+i+')').addClass("rsActiveSlide");
  					}else {
  						if($('.home-section:eq('+i+')').is('.rsActiveSlide')){
  							$('.home-section:eq('+i+')').removeClass("rsActiveSlide");
  						}
  					}
  				});
  			}
  			checkScrollPos();
			//
		  }else{
		  	/*******************************************************************************************************************

		  	DESKTOP HOME SLIDES TRANSITIONS

		  	*******************************************************************************************************************/
			  $(".home-sections").royalSlider({
				autoplay: true,
				slidesOrientation: 'vertical',
				loop: false,
				keyboardNavEnabled: true,
				speed: 900,
				slidesToShow: 1,
				slide: ".home-section",
				slidesSpacing: 0,
				addActiveClass: true,
				controlNavigation: 'none',
				navigateByClick: false,
				arrowsNav: false,
				controlsInside: false,
			  });
		  
			  var win = $(window),
			  nextSection = $('.home-sections-nav a.arrow.down'),
			  prevSection = $('.home-sections-nav a.arrow.up'),
			  threshold = 60,
			  isAtEnd = false,
			  isAnimating = false,
			  si = $('.home-sections').data('royalSlider');
		
			  var mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel"; //FF doesn't recognize mousewheel as of FF3.x
			//
	  		$('.home-sections').bind(mousewheelevt, function(event){
				//console.log('MOUSEWHEEL EVENT!!! Should i do parallax? : '+doParallax);
				var evt = win.event || event;   
		    	evt = evt.originalEvent ? evt.originalEvent : evt;            
		    	var delta = evt.detail ? evt.detail*(-40) : evt.wheelDelta;
		    	//console.log('THIS IS THE DELTA : '+delta);
		    	if(delta > threshold && !isAnimating){ 
					if(si.currSlideId !== 0){
						//console.log('GOING TO PREVIOUS SCREEN!!!');
						isAnimating = true;
				    	si.prev();
					}
		    	} else if(delta < -1*threshold && !isAnimating){
					if(si.currSlideId !== si.numSlides-1){
						//console.log('GOING TO NEXT SCREEN!!!');
						isAnimating = true;
			    		si.next();
					}
		    	}
	  		});
		
			si.ev.on('rsAfterSlideChange', function() {
				 
				if(si.currSlideId === si.numSlides-1 ){
					$('footer').animate({'margin-top':'-100px'});
				}else{
					$('footer').animate({'margin-top':'0px'});
				}
				//
				if(si.currSlideId === si.numSlides-1 && nextSection.css("maxHeight") !== "0px"){
					nextSection.css("maxHeight", "0px");
				}else if(si.currSlideId !== si.numSlides-1 && nextSection.css("maxHeight") === "0px"){
					var cH = nextSection.find('.link').outerHeight();
					nextSection.css("maxHeight", cH+"px");
				}
				//
				if(si.currSlideId === 0 && prevSection.css("maxHeight") !== "0px"){
					prevSection.css("maxHeight", "0px");
				}else if(si.currSlideId !== 0 && prevSection.css("maxHeight") === "0px"){
					var cH = prevSection.find('.link').outerHeight();
					prevSection.css("maxHeight", cH+"px");
				}
				setTimeout(function(){ isAnimating = false; }, 500); // Add a slight delay to resetting isAnimating to prevent doubling up of slide transition
			});
		
			nextSection.click(function(e) {
				e.preventDefault();
				//
				if(si.currSlideId !== si.numSlides-1){
					//console.log('GOING TO NEXT SCREEN!!!');
					isAnimating = true;
		    		si.next();
					//
				}
				//
				return false;
			});
		
			prevSection.click(function(e) {
				e.preventDefault();
				//
				if(si.currSlideId !== 0){
					//console.log('GOING TO NEXT SCREEN!!!');
					isAnimating = true;
		    		si.prev();
					//
				}
				//
				return false;
			});
			//
		  }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
		  
		  
		if(!$('body').is('.device')){
			
  	  		/*******************************************************************************************************************
        	
  	  		CONTACT SLIDES TRANSITIONS
        	
  	  		*******************************************************************************************************************/
  			$(".contact-locations").royalSlider({
  				autoplay: true,
  				slidesOrientation: 'vertical',
  				loop: false,
  				keyboardNavEnabled: true,
  				speed: 900,
  				slidesToShow: 1,
  				slide: ".home-section",
  				slidesSpacing: 0,
  				addActiveClass: true,
  				controlNavigation: 'none',
  				navigateByClick: false,
  				arrowsNav: false,
  				controlsInside: false,
  			});
			
  			var win = $(window),
  			nextSection = $('.contact-locations-nav a.arrow.down'),
  			prevSection = $('.contact-locations-nav a.arrow.up'),
  			threshold = 40,
  			isAtEnd = false,
  			isAnimating = false,
  			si = $('.contact-locations').data('royalSlider');
			
  			var mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel"; //FF doesn't recognize mousewheel as of FF3.x
  			//
    		$('.contact-locations').bind(mousewheelevt, function(event){
  				//console.log('MOUSEWHEEL EVENT!!! Should i do parallax? : '+doParallax);
  				var evt = win.event || event;   
  	    		evt = evt.originalEvent ? evt.originalEvent : evt;            
  	    		var delta = evt.detail ? evt.detail*(-40) : evt.wheelDelta;
  	    		//console.log(delta);
  	    		if(delta > threshold && !isAnimating){ 
  					if(si.currSlideId !== 0){
  						//console.log('GOING TO PREVIOUS SCREEN!!!');
  						isAnimating = true;
  				    	si.prev();
  					}
  	    		} else if(delta < -1*threshold && !isAnimating){
  					if(si.currSlideId !== si.numSlides-1){
  						//console.log('GOING TO NEXT SCREEN!!!');
  						isAnimating = true;
  			    		si.next();
  					}
  	    		}
    		});
			
  			si.ev.on('rsAfterSlideChange', function() {
  				//console.log('SLIDE HAS CHANGED!!');
  				if(si.currSlideId === si.numSlides-1 && $('body').hasClass("no-overflow")){
  					$('body').removeClass("no-overflow");
  				}else if(!$('body').hasClass("no-overflow")){
  					$('body').addClass("no-overflow");
  				}
  				//
  				if(si.currSlideId === si.numSlides-1 && nextSection.css("maxHeight") !== "0px"){
  					nextSection.css("maxHeight", "0px");
  				}else if(si.currSlideId !== si.numSlides-1 && nextSection.css("maxHeight") === "0px"){
  					var cH = nextSection.find('.link').outerHeight();
  					nextSection.css("maxHeight", cH+"px");
  				}
  				//
  				if(si.currSlideId === 0 && prevSection.css("maxHeight") !== "0px"){
  					prevSection.css("maxHeight", "0px");
  				}else if(si.currSlideId !== 0 && prevSection.css("maxHeight") === "0px"){
  					var cH = prevSection.find('.link').outerHeight();
  					prevSection.css("maxHeight", cH+"px");
  				}
				setTimeout(function(){ isAnimating = false; }, 500); // Add a slight delay to resetting isAnimating to prevent doubling up of slide transition
  			});
			
  			nextSection.click(function(e) {
  				e.preventDefault();
  				//
  				if(si.currSlideId !== si.numSlides-1){
  					//console.log('GOING TO NEXT SCREEN!!!');
  					isAnimating = true;
  	    			si.next();
  					//
  				}
  				//
  				return false;
  			});
			
  			prevSection.click(function(e) {
  				e.preventDefault();
  				//
  				if(si.currSlideId !== 0){
  					//console.log('GOING TO NEXT SCREEN!!!');
  					isAnimating = true;
  	    			si.prev();
  					//
  				}
  				//
  				return false;
  			});
			
		}  
		  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // News page
    'news': {
      init: function() {
		  	// JavaScript to be fired on the news page
		  	if (!location.origin)
		    	location.origin = location.protocol + "//" + location.host;
		  
  			var page = 1,
  			loading = true,
  			win = $(window),
  		  	url = location.origin,
  			content = $(".posts-container"),
  		  	more = $('.more-posts'),
  			moreBtn = $('.more-posts a'),
			isArchive = false,
  			$i = $('a.post').last().attr("data-i"),
  			$j = $('a.post').last().attr("data-j");
			//
			if(content.is('.archive')){
				isArchive = true;
				var type = content.attr('data-type');
				var category = content.attr('data-cat');
			}
			//
  			if($i == undefined){
  				$i = 0;
  			}
  			if($j == undefined){
  				$j = 0;
  			}
			//
			load_posts = function(i, j){
				//console.log('LOADING POSTS!!!');
			  	$.ajax({
			    	type       : "GET",
			    	data       : {numPosts : 5, pageNumber: page, isArchive: isArchive, type: type, cat: category, $i: $i, $j: $j},
			    	dataType   : "html",
			    	url        : url+"/content/themes/jamfilled/load-handler.php",
			    	beforeSend : function(){
						if(page !== 1){
							$('.loader').fadeIn(333);
						}
			    	},
			    	success : function(data){
			    	    $data = $(data);
						//console.log('SUCCESSFULLY GOT CONTENT: '+JSON.stringify($data));
						//console.log('THIS IS THE LENGTH OF THE DATA : '+$data.length);
			    	    if($data.length !== 0){
			    	    	//we still have data
				    	    $data.hide();
				    	    content.append($data);
							$('.loader').fadeOut(333);
				    	    $data.fadeIn(333, function(){
				    	        loading = false;
				    	    });
			    	    }else{
			    	    	$('.loader').fadeOut(333, function(){
			    	    		more.slideToggle(333);
			    	    	});
			    	    }
			    	},
			    	error : function(jqXHR, textStatus, errorThrown) {
			    	    alert(jqXHR + " :: " + textStatus + " :: " + errorThrown);
			    	}
				});
			};
			moreBtn.click(function(e){
				e.preventDefault();
				//
				if(!loading){
					loading = true;
					page++;
					$i = $('a.post').last().attr("data-i");
					$i++;
					$j = $('a.post').last().attr("data-j");
					if($('a.post').last().is('.half')){
						$j++;
					}
					load_posts();
				}
				//
				return false;
			});
			//
			win.scroll(function() {
			    var content_offset = content.offset(); 
			    if(!loading && (win.scrollTop() + win.height()) > (content.scrollTop() + content.height() + content_offset.top)) {
					loading = true;
					page++;
					$i = $('a.post').last().attr("data-i");
					$i++;
					$j = $('a.post').last().attr("data-j");
					if($('a.post').last().is('.half')){
						$j++;
					}
					load_posts($i, $j);
			    }
			});
			//
			load_posts($i, $j);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
 
	  	/*******************************************************************************************************************

	  	IMAGE GALLERY FOR ABOUT PAGE

	  	*******************************************************************************************************************/
		  
		  $(".about-column .gallery .content").royalSlider({
			autoplay: true,
			slidesOrientation: 'horizontal',
			loop: true,
			keyboardNavEnabled: true,
			speed: 900,
			slidesToShow: 1,
			slide: ".slide",
			slidesSpacing: 0,
			addActiveClass: true,
			controlNavigation: 'bullets',
			navigateByClick: true,
			arrowsNav: false,
			controlsInside: false,
		  });
		  
	  	
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.